import React, { useContext } from 'react'
import { ButtonProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { JobsPageContext } from '../useJobsPage'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'

type ContainerProps = ButtonProps & {
  className?: string
}

type Props = ContainerProps & {
  isOverlayed: () => boolean
  tooltipLabel: string
  onClick: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { className, isOverlayed, tooltipLabel, onClick } = props
  const { t } = useTranslation()

  return (
    <OverlayTriggerButton
      buttonClassName={className}
      buttonLabel={t('プロジェクト登録')}
      isOverlayed={isOverlayed}
      tooltipId="register-project-button-tooltip"
      tooltipLabel={t(tooltipLabel)}
      onClick={onClick}
      data-testid="register-project-button"
    />
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = useContext(JobsPageContext)
  const { t } = useTranslation()

  const statuses = state.selectedJobs.map(job => job.status)

  const projectIdsOfSelectedJobsAreNotSet = ((): boolean => {
    const projectIdTypes = state.selectedJobs.map(job => {
      return typeof job.projectId
    })

    return projectIdTypes.indexOf('string') === -1 ? true : false
  })()

  const langPairsOfSelectedJobsAreIdentical = ((): boolean => {
    const sourceLangs = ((langs: string[]): string[] => {
      return Array.from(new Set(langs))
    })(
      state.selectedJobs.map(job => {
        return job.srcLang
      })
    )

    const targetLangs = ((langs: string[]): string[] => {
      return Array.from(new Set(langs))
    })(
      state.selectedJobs.map(job => {
        return job.tgtLang
      })
    )

    return sourceLangs.length === 1 && targetLangs.length === 1 ? true : false
  })()

  const isMtJob = ((): boolean => {
    return state.selectedJobs.some(job => job.name.includes('_MT_'))
  })()

  const selectedJobsIncludesInitializingJobs = statuses.includes('initializing') ? true : false
  const selectedJobsIncludesErrorJobs = statuses.includes('error') ? true : false
  const selectedJobsIncludesRejectedJobs = statuses.includes('rejected') ? true : false

  const isOverlayed = (): boolean => {
    if (
      projectIdsOfSelectedJobsAreNotSet &&
      langPairsOfSelectedJobsAreIdentical &&
      !selectedJobsIncludesInitializingJobs &&
      !selectedJobsIncludesRejectedJobs &&
      !selectedJobsIncludesErrorJobs &&
      state.selectedJobs.length <= 500 &&
      !isMtJob
    ) {
      return false
    }
    return true
  }

  const onClick = (): void => {
    dispatch({ type: 'SET_SHOW_REGISTER_PROJECT_DIALOG', payload: { show: true } })
  }

  const tooltipLabel =
    state.selectedJobs.length === 0
      ? 'ジョブが選択されていません'
      : ((): string => {
          const messages = []
          if (!projectIdsOfSelectedJobsAreNotSet) {
            messages.push(t('束ね済みのジョブが選択されています'))
          }
          if (!langPairsOfSelectedJobsAreIdentical) {
            messages.push(t('異なる言語ペアのジョブが選択されています'))
          }
          if (selectedJobsIncludesInitializingJobs) {
            messages.push(t('準備中のジョブが選択されています'))
          }
          if (selectedJobsIncludesErrorJobs) {
            messages.push(t('エラーになっているジョブが選択されています'))
          }
          if (selectedJobsIncludesRejectedJobs) {
            messages.push(t('否認されたジョブが選択されています'))
          }
          if (state.selectedJobs.length > 500) {
            messages.push(t('選択されているジョブが500件を超えています'))
          }
          if (isMtJob) {
            messages.push(t('MTジョブが選択されています'))
          }

          return messages.join('\n')
        })()

  return (
    <Component {...props} isOverlayed={isOverlayed} tooltipLabel={tooltipLabel} onClick={onClick} />
  )
}

Container.displayName = 'RegisterProjectButton'
export default Container
