import React from 'react'
import { Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import config from '../../config'
import { useLoginUser } from '../../hooks/useLoginUser'

type Props = RouteComponentProps & {
  signOut: () => void
}

const AppHeader: React.FC<Props> = (props: Props) => {
  const loginUser = useLoginUser()
  const { location } = props

  // ルートパスのみに置換。
  // 例1: /projects/123 → /projects
  // 例2: /projects → /projects
  const pathname = location.pathname.replace(/(?<=^\/[^/]+)\/.+$/g, '')

  const { i18n, t } = useTranslation()

  return (
    <Navbar variant="dark" style={{ backgroundColor: config[config.STAGE].headerColor }}>
      <LinkContainer to="/">
        <Navbar.Brand>{config[config.STAGE].headerTitle}</Navbar.Brand>
      </LinkContainer>
      <Navbar.Collapse>
        <Nav activeKey={pathname} className="mr-auto">
          {loginUser?.organizationType !== 'vendor' && (
            <>
              <LinkContainer to="/jobs?archivedAt=null">
                <Nav.Link eventKey="/jobs" data-testid="nav-link-to-job-list">
                  {t('ジョブ一覧')}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/projects?archivedAt=null">
                <Nav.Link eventKey="/projects" data-testid="nav-link-to-project-list">
                  {t('プロジェクト一覧')}
                </Nav.Link>
              </LinkContainer>
              <NavDropdown id="tm-entries-dropdown" title={t('TMエントリ一覧')}>
                <NavDropdown.Item onClick={(): void => props.history.push('/tm-entries')}>
                  {t('TMエントリ一覧')} (v2)
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={(): void => {
                    window.open(config[config.STAGE].translationDbUrl)
                    return
                  }}
                >
                  TranslationDB Browser <FontAwesomeIcon icon={faExternalLinkAlt} />
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
        </Nav>
        {loginUser?.organizationType === 'owner-group' && (
          <Nav>
            <NavItem>
              <a
                className="nav-external-link"
                href="https://misumig.sharepoint.com/sites/translation"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="information-link"
              >
                {t('ヘルプ')}
              </a>
            </NavItem>
          </Nav>
        )}
        <Nav>
          <NavDropdown
            alignRight
            id="user-lang-dropdown"
            title={i18n.language === 'en' ? 'English' : '日本語'}
            data-testid="select-user-lang"
          >
            <NavDropdown.Item
              onClick={(): void => {
                localStorage.setItem('userLang', 'en')
                i18n.changeLanguage('en')
              }}
              data-testid="select-user-lang-en"
            >
              English
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={(): void => {
                localStorage.setItem('userLang', 'ja')
                i18n.changeLanguage('ja')
              }}
              data-testid="select-user-lang-ja"
            >
              日本語
            </NavDropdown.Item>
          </NavDropdown>
          {loginUser?.role === 'admin' && (
            <NavDropdown
              alignRight
              id="admin-menu-dropdown"
              title={t('管理メニュー')}
              data-testid="administration-menu"
            >
              <NavDropdown.Item
                onClick={(): void => props.history.push('/admin/users')}
                data-testid="administration-menu-users"
              >
                {t('ユーザー')}
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={(): void => props.history.push('/admin/vendors')}
                data-testid="administration-menu-vendors"
              >
                {t('翻訳会社')}
              </NavDropdown.Item>
            </NavDropdown>
          )}
          <NavDropdown alignRight id="user-menu-dropdown" title={loginUser?.username}>
            <NavDropdown.Item onClick={(): void => props.signOut()}>
              {t('ログアウト')}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default withRouter(AppHeader)
