import React, { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ICellRendererParams } from 'ag-grid-community'

import config from '../../config'

const DownloadButtonRenderer = (params: ICellRendererParams): JSX.Element => {
  const { t } = useTranslation()

  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [previewHtmlExists, setPreviewHtmlExists] = useState(false)

  const onToggle = async (isOpen: boolean): Promise<void> => {
    if (isOpen) {
      const jobId = params.data._id
      const jobName = params.data.name
      const response = await axios.post(`${config[config.STAGE].endpoint}/api/v1/objectExists`, {
        key: `jobs/${jobName}-${jobId}/workfiles/html/${jobName}-index.html`
      })
      setPreviewHtmlExists(response.data.exists)
    }
  }

  const download = async (tsvType: string): Promise<void> => {
    const jobId = params.data._id
    const jobName = params.data.name
    const objectKey =
      tsvType === 'src'
        ? `jobs/${jobName}-${jobId}/workfiles/source/${jobName}`
        : `jobs/${jobName}-${jobId}/workfiles/translated/${jobName}`

    const downloadUrlResponse = await axios.post(
      `${config[config.STAGE].endpoint}/api/v1/generateDownloadUrl`,
      {
        objectKey
      }
    )

    if (downloadUrlResponse.data.exists === true) {
      const presignedUrl = downloadUrlResponse.data.presignedUrl
      window.open(presignedUrl, '_blank')
    } else {
      setShowErrorDialog(true)
    }
  }

  return (
    <>
      {params.data ? (
        <>
          <Dropdown drop="down" style={{ position: 'absolute', top: 0 }} onToggle={onToggle}>
            <Dropdown.Toggle size="sm" variant="link" id="download-button">
              <FontAwesomeIcon icon={faDownload} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={(): Promise<void> => download('src')}>
                {t('翻訳元TSV')}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={
                  params.data.status !== 'translated' &&
                  params.data.status !== 'reviewed' &&
                  params.data.status !== 'completed'
                }
                eventKey="2"
                onClick={(): Promise<void> => download('tgt')}
              >
                {t('翻訳済みTSV')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Modal onHide={(): void => setShowErrorDialog(false)} show={showErrorDialog}>
            <Modal.Header closeButton />
            <Modal.Body>{t('ファイルが存在しません。')}</Modal.Body>
            <Modal.Footer>
              <Button onClick={(): void => setShowErrorDialog(false)}>{t('OK')}</Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DownloadButtonRenderer
