import Job, { getChildResourceName, getDownloadUrl, getProjectName } from '../../lib/Job'

type RowData = {
  _id: string
  name: string
  origin?: string
  contentType?: string
  subjectId?: string
  srcLang: string
  tgtLang: string
  fromSubsidiaryCode?: string
  toSubsidiaryCode?: string
  brandCode?: string
  categoryCode?: string
  status?: string
  projectId?: string
  parentName?: string
  vendor?: string
  owner?: string
  ownerGroup?: string
  numberOfBytes?: number
  wordCount?: number
  weightedWordCount?: number
  calculatedAmount?: number
  dueAt?: Date
  orderedAt?: Date
  startReviewAt?: Date
  reviewDueAt?: Date
  completedAt?: Date
  createdAt?: Date
  updatedAt?: Date
  archivedAt?: Date
  unitStatistics?: {
    untranslatedCount?: number
    totalCount: number
    unsignedoffCount?: number
  }
  unitsPerXliff?: number
  version?: string
}

export const JobToRowData = async (job: Job, showJobNameAsLink?: boolean): Promise<RowData> => {
  let jobName
  if (showJobNameAsLink) {
    const { presignedUrl, exists } = await getDownloadUrl(job)
    if (exists) {
      jobName = `<a href="${presignedUrl}">${job.name}</a>`
    } else {
      jobName = job.name
    }
  } else {
    jobName = job.name
  }
  return {
    _id: job._id,
    name: jobName,
    origin: job.origin ? job.origin : job.externalId ? 'pim' : 'dashboard',
    contentType: job.contentType,
    subjectId: typeof job.properties['anken_id'] === 'string' ? job.properties['anken_id'] : '',
    srcLang: job.srcLang,
    tgtLang: job.tgtLang,
    fromSubsidiaryCode:
      typeof job.properties['from_subsidiary_code'] === 'string'
        ? job.properties['from_subsidiary_code']
        : '',
    toSubsidiaryCode:
      typeof job.properties['to_subsidiary_code'] === 'string'
        ? job.properties['to_subsidiary_code']
        : '',
    categoryCode:
      typeof job.properties['category_code'] === 'string' ? job.properties['category_code'] : '',
    brandCode: typeof job.properties['brand_code'] === 'string' ? job.properties['brand_code'] : '',
    status: job.status,
    projectId: job.projectId,
    parentName: getProjectName(job),
    vendor: getChildResourceName(job, 'vendor'),
    owner: getChildResourceName(job, 'owner'),
    ownerGroup: getChildResourceName(job, 'ownerGroup'),
    numberOfBytes: job.numberOfBytes,
    wordCount: job.wordCount,
    weightedWordCount: job.weightedWordCount,
    calculatedAmount: job.calculatedAmount,
    dueAt: job.dueAt,
    orderedAt: job.orderedAt,
    startReviewAt: job.startReviewAt,
    reviewDueAt: job.reviewDueAt,
    completedAt: job.completedAt,
    createdAt: job.createdAt,
    updatedAt: job.updatedAt,
    archivedAt: job.archivedAt,
    unitStatistics: {
      untranslatedCount: job.unitStatistics?.untranslatedCount ?? 0,
      totalCount: job.unitStatistics?.totalCount ?? 0,
      unsignedoffCount: job.unitStatistics?.unsignedoffCount ?? 0
    },
    unitsPerXliff: job.project?.unitsPerXliff,
    version: job.version
  }
}

export default RowData
