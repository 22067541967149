import React from 'react'
import { Button, Modal, Form, Row, Col, ListGroup, Badge } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { formatBytes } from '../../../lib/Utils'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import JobList from '../../JobList'
import { useUploadTranslatedXliffsDialog, State, Action } from './useUploadTranslatedXliffsDialog'
import { BackendError } from '../../../lib/BackendError'

type ContainerProps = {
  close: Function
  title: string
  projectId: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, handleSubmit } = props
  const { t } = useTranslation()

  const onSelectedFilesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const targetFiles = e.target.files || []
    const files = Array.from(targetFiles).map(f => f)

    dispatch({
      type: 'SET_SELECTED_FILES',
      payload: { selectedFiles: files }
    })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '' } })
  }

  return (
    <Modal
      onHide={(): void => props.close()}
      show={true}
      size="lg"
      data-testid="upload-translated-xliffs-dialog"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="xliffZipFiles">
            <Col sm={12}>
              <Form.File
                id="xliff-zip-files"
                label={t('ZIPファイルを選択')}
                accept=".zip"
                onChange={onSelectedFilesChange}
                custom
                required
                data-testid="select-xliff-file"
              ></Form.File>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="selectedFilesCount">
            <Col sm={12}>
              <div>
                {t('選択中のファイル数')}: {state.selectedFiles.length}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="selectedFiles">
            <Col sm={12}>
              <ListGroup>
                {state.selectedFiles.map(file => (
                  <ListGroup.Item
                    key={file.name}
                    className={'d-flex justify-content-between align-items-center'}
                  >
                    {file.name}{' '}
                    <Badge pill variant="primary">
                      {formatBytes(file.size)}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="uploadedFiles" style={{ height: '50vh' }}>
            <Col sm={12}>
              <JobList options={`projectId=${props.projectId}`} showUnitStatisticsColumns={true} />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={{ color: 'red' }}>{state.message.text}</span>
          <OverlayTriggerButton
            type="submit"
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            buttonLabel={t('OK')}
            tooltipLabel={t('アップロード中')}
            tooltipId="tooltip-uploading-in-progress"
            variant="primary"
            data-testid="submit-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => props.close()}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, projectId } = props
  const { state, dispatch, handleSubmit: uploadXliffs } = useUploadTranslatedXliffsDialog(projectId)
  const { t } = useTranslation()

  const hasValidFiles = (): boolean => state.selectedFiles.every(f => /\.zip$/.test(f.name))

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    dispatch({ type: 'SET_MESSAGE', payload: { message: '' } })
    if (!hasValidFiles()) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: t('ZIP以外のファイルが含まれています'), isError: true }
      })
      return
    }

    dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
    try {
      await uploadXliffs()
      close()
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'UploadTranslatedXliffsDialog'
export default Container
