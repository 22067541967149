import { ICellRendererParams } from 'ag-grid-community'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Job from '../../lib/Job'
import config from '../../config'

const OpenEditorButtonRenderer = (params: ICellRendererParams): JSX.Element => {
  const job = params.data as Job & { unitsPerXliff: number }
  const editorUrl = config[config.STAGE].editorUrl
  const htmlPreviewEndpoint = config[config.STAGE].htmlPreviewEndpoint
  const proxyTenant = config[config.STAGE].proxyTenant

  const toProxyLangCode = (langCode: string): string => {
    if (langCode.startsWith('zh')) {
      return langCode === 'zh-CN' ? 'cn' : 'tw'
    }
    if (langCode.startsWith('es')) {
      return langCode === 'es-MX' ? 'mx' : 'es'
    }
    return langCode.slice(0, 2)
  }

  if (!job) return <></>
  // unitsPerXliffが存在する(=翻訳キット生成済み)projectのみボタンを活性化する
  return (
    <Button
      href={
        job.version === 'v3'
          ? `https://${proxyTenant}-${toProxyLangCode(
              params.data.srcLang
            )}.${htmlPreviewEndpoint}/${params.data.tgtLang}/api/v1/html/${
              params.data.name
            }-index.html`
          : `${editorUrl}/jobs/${job._id}/edit`
      }
      target="_blank"
      as="a"
      variant="link"
      disabled={!editorUrl || !job.unitsPerXliff}
    >
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </Button>
  )
}

export default OpenEditorButtonRenderer
