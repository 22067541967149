import { ICellRendererParams } from 'ag-grid-community'
import Job from './Job'

const JobNameCellRenderer = (params: ICellRendererParams): string => {
  if (params.data) {
    return params.value
  } else {
    return '<img src="loading.gif" alt="Loading..." />'
  }
}

const ProjectNameCellRenderer = (params: ICellRendererParams): string => {
  if (params.data) {
    if (params.colDef.field === 'parentName') {
      return `<a href='/#/projects/${params.data.projectId}'>${params.value}</a>`
    }
    return `<a href='/#/projects/${params.data._id}'>${params.value}</a>`
  } else {
    return '<img src="loading.gif" alt="Loading..." />'
  }
}

const DisabledCellRenderer = (params: ICellRendererParams): string => {
  if (params.data) {
    if (params.node.selectable === true) {
      return params.value
    }
    return `<span style="color: #999"}>${params.value}</span>`
  } else {
    return ''
  }
}

const TMSourceCellRenderer = (params: ICellRendererParams): string => {
  if (params.data) {
    return `<span style="height: 100px!important; overflow: hidden!important">${params.value}</span>`
  } else {
    return '<img src="loading.gif" alt="Loading..." />'
  }
}

const UnitStatisticsCellRenderer = (params: ICellRendererParams): string => {
  if (params.data) {
    const job = params.data as Job
    if (job.version === 'v3') {
      return `${job.unitStatistics?.unsignedoffCount ?? 0}/${job.unitStatistics?.totalCount ?? 0}`
    }
    return `${job.unitStatistics?.untranslatedCount ?? 0}/${job.unitStatistics?.totalCount ?? 0}`
  } else {
    return ''
  }
}

export {
  DisabledCellRenderer,
  JobNameCellRenderer,
  ProjectNameCellRenderer,
  TMSourceCellRenderer,
  UnitStatisticsCellRenderer
}
