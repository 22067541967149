import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Project from '../../../lib/Project'
import useJobFiltersDialog, { State, DispatchType } from './useJobFiltersDialog'

type ContainerProps = RouteComponentProps & {
  close: Function
  project?: Project
  title?: string
}

type Props = ContainerProps & {
  state: State
  dispatch: DispatchType
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, onKeyPress, handleSubmit } = props
  const { t } = useTranslation()

  const clearFilters = (): void => {
    dispatch({ type: 'CLEAR_FILTERS' })
  }

  return (
    <Modal
      onHide={(): void => props.close()}
      show={true}
      size="lg"
      data-testid="job-filters-dialog"
    >
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('絞り込み')}</Modal.Header>
        <Modal.Body>
          {!props.project && (
            <Form.Group as={Row} controlId="bundled">
              <Form.Label className="formTableCell" column sm={4}>
                {t('束ね状況')}:
              </Form.Label>
              <Col sm="auto">
                <Form.Check
                  checked={state.formData.showBundled}
                  id="all-jobs"
                  type="radio"
                  label={t('すべて')}
                  name="bundled"
                  onChange={(): void => {
                    dispatch({ type: 'SET_FORM_VALUE', payload: { showBundled: true } })
                  }}
                  data-testid="show-bundled"
                ></Form.Check>
              </Col>
              <Col sm="auto">
                <Form.Check
                  checked={!state.formData.showBundled}
                  id="unbundled-jobs"
                  type="radio"
                  label={t('未束ね案件のみ')}
                  name="bundled"
                  onChange={(): void => {
                    dispatch({ type: 'SET_FORM_VALUE', payload: { showBundled: false } })
                  }}
                  data-testid="hide-bundled"
                ></Form.Check>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} controlId="ankenId">
            <Form.Label column sm={4}>
              {t('案件ID')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { ankenId: e.currentTarget.value } })
                }
                value={state.formData.ankenId}
                data-testid="anken-id"
              />
              <Form.Text>{t('前方一致')}</Form.Text>
            </Col>
          </Form.Group>
          {!props.project && (
            <>
              <Form.Group as={Row} controlId="srcLang">
                <Form.Label column sm={4}>
                  {t('ソース言語')}:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                      dispatch({
                        type: 'SET_FORM_VALUE',
                        payload: { srcLang: e.currentTarget.value }
                      })
                    }
                    value={state.formData.srcLang}
                    data-testid="src-lang"
                  >
                    <option value="">{t('指定なし')}</option>
                    <option value="de-DE">{t('German')}</option>
                    <option value="en-US">{t('English (US)')}</option>
                    <option value="es-MX">{t('Spanish (Mexico)')}</option>
                    <option value="fr-FR">{t('French')}</option>
                    <option value="it-IT">{t('Italian')}</option>
                    <option value="ja-JP">{t('Japanese')}</option>
                    <option value="ko-KR">{t('Korean')}</option>
                    <option value="zh-CN">{t('Chinese (Simplified)')}</option>
                    <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
                    <option value="th-TH">{t('Thai')}</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="tgtLang">
                <Form.Label column sm={4}>
                  {t('ターゲット言語')}:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                      dispatch({
                        type: 'SET_FORM_VALUE',
                        payload: { tgtLang: e.currentTarget.value }
                      })
                    }
                    value={state.formData.tgtLang}
                    data-testid="tgt-lang"
                  >
                    <option value="">{t('指定なし')}</option>
                    <option value="de-DE">{t('German')}</option>
                    <option value="en-US">{t('English (US)')}</option>
                    <option value="es-MX">{t('Spanish (Mexico)')}</option>
                    <option value="fr-FR">{t('French')}</option>
                    <option value="it-IT">{t('Italian')}</option>
                    <option value="ja-JP">{t('Japanese')}</option>
                    <option value="ko-KR">{t('Korean')}</option>
                    <option value="zh-CN">{t('Chinese (Simplified)')}</option>
                    <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
                    <option value="th-TH">{t('Thai')}</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </>
          )}
          <Form.Group as={Row} controlId="status">
            <Form.Label column sm={4}>
              {t('ステータス')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { status: e.currentTarget.value } })
                }
                value={state.formData.status}
                data-testid="status"
              >
                <option value="">{t('指定なし')}</option>
                <option value="initializing">{t('準備中')}</option>
                <option value="prepared">{t('準備処理完了')}</option>
                <option value="order_confirmed">{t('発注確定')}</option>
                <option value="translated">{t('翻訳済み')}</option>
                <option value="reviewed">{t('レビュー済み')}</option>
                <option value="completed">{t('完了')}</option>
                <option value="rejected">{t('完了（否認）')}</option>
                <option value="error">{t('エラー')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {!props.project && (
            <>
              <Form.Group as={Row} controlId="categoryCode">
                <Form.Label column sm={4}>
                  {t('カテゴリコード')}:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    className="formTableCell"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      dispatch({
                        type: 'SET_FORM_VALUE',
                        payload: { categoryCode: e.currentTarget.value }
                      })
                    }
                    value={state.formData.categoryCode}
                    data-testid="category-code"
                  />
                  <Form.Text>{t('前方一致')}</Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="brandCode">
                <Form.Label column sm={4}>
                  {t('ブランドコード')}:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    className="formTableCell"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      dispatch({
                        type: 'SET_FORM_VALUE',
                        payload: { brandCode: e.currentTarget.value }
                      })
                    }
                    value={state.formData.brandCode}
                    data-testid="brand-code"
                  />
                  <Form.Text>{t('前方一致')}</Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="createdAt">
                <Form.Label column sm={4}>
                  {t('作成日時')}:
                </Form.Label>
                <Col sm="auto">
                  <DatePicker
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={(date): void =>
                      dispatch({ type: 'SET_FORM_VALUE', payload: { createdAtFrom: date } })
                    }
                    selected={state.formData.createdAtFrom}
                    showTimeSelect
                    customInput={<input data-testid="created-at-from" type="text" />}
                  />
                </Col>
                <Col sm="auto">{' 〜 '}</Col>
                <Col sm="auto">
                  <DatePicker
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={(date): void =>
                      dispatch({
                        type: 'SET_FORM_VALUE',
                        payload: { createdAtTo: date }
                      })
                    }
                    selected={state.formData.createdAtTo}
                    showTimeSelect
                    customInput={<input data-testid="created-at-to" type="text" />}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  {t('アーカイブ済みジョブ')}:
                </Form.Label>
                <Col sm={8}>
                  <Form.Check id="show-archived-projects-checkbox">
                    <Form.Check.Input
                      checked={state.formData.showArchived}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: { showArchived: e.currentTarget.checked }
                        })
                      }}
                      data-testid="show-archived"
                    />
                    <Form.Check.Label>{t('表示する')}</Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Group>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={clearFilters} variant="light">
              {t('絞り込み条件をクリア')}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" data-testid="ok-button">
            {t('OK')}
          </Button>
          <Button onClick={(): void => props.close()} variant="secondary">
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, project } = props
  const { state, dispatch, handleSubmit: setFilters } = useJobFiltersDialog(
    props.location.search,
    project
  )

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setFilters()
    close()
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'JobFiltersDialog'
export default withRouter(Container)
